<template>
  <div class="nounExplain">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/cidian' }"
        >政策词典</el-breadcrumb-item
      >
      <el-breadcrumb-item>名词解释</el-breadcrumb-item>
    </el-breadcrumb>
    <div >
      <cidainSearch />
      <div class="noun">名词解释</div>
      <div class="explain">
        <div>
          <h1>{{ list.title }}</h1>
          <div v-html="list.content"></div>
        </div>
        <ul>
          <div>相关名词解释</div>
          <li v-if="list2.length == 0">暂无相关名词解释</li>
          <div v-else>
            <li
              v-for="item in list2"
              :key="item.id"
              :title="item.title"
              @click="goNounExplain(item.id)"
            >
              {{ item.title }}
            </li>
          </div>
        </ul>
      </div>
      <div class="relevant">
        <div>相关政策</div>
        <div
          v-if="list3.length == 0"
          class="policy"
          style="color: #1f88f9; font-size: 14px"
        >
          暂无相关政策
        </div>
        <div v-else>
          <div
            v-for="item in list3"
            class="policy"
            :key="item.id"
            :title="item.title"
            @click="goToZC(item.id)"
          >
            <div>{{ item.title }}</div>
            <div>
              <span>{{ item.fw_department }}</span>
              <span>{{ item.dispatch_date }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cidainSearch from "./components/cidianSearch";
import { request } from "@/network";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    cidainSearch,
  },
  data() {
    return {
      id: "",
      list: "",
      list2: [],
      list3: [],
      isFirst: true,
    };
  },
  computed: {
    ...mapState("cidian", ["hotList"]),
    ...mapState({
      selectCity: (state) => state.menu.selectCity,
    }),
  },
  watch: {
    selectCity: {
      handler(val) {
        if (!this.isFirst)
          this.getList3(
            JSON.parse(window.sessionStorage.getItem("choosecity"))
          );
      },
      immediate: true,
    },
  },
  mounted() {
    this.getData();
    if (this.hotList.length <= 0) this.getFindDictionarySearch();
  },
  methods: {
    ...mapMutations("cidian", ["SET_HOT_LIST"]),
    goToZC(id) {
      this.$router.push({
        path: "/formaldetails",
        query: {
          id,
        },
      });
    },
    getData() {
      this.id = this.$route.query.id;
      this.getList().then(() => {
        this.getList2().then(() => {
          this.getList3();
        });
      });
    },
    async getList() {
      const { data: res } = await request({
        method: "POST",
        url: "/EweiShopQaQuestion/findConditionsById",
        data: {
          id: this.id,
        },
      });
      this.list = res.data;
    },
    async getList2() {
      const { data: res } = await request({
        method: "GET",
        url: "/EweiShopQaQuestion/findMore",
        params: {
          keyWord: this.list.title,
          pageNo: 1,
          pageSize: 6,
        },
      });
      this.list2 = res.data;
    },
    async getList3(val) {
      // console.log(JSON.parse(window.sessionStorage.getItem('choosecity')));
      const chooseCity =
        val || JSON.parse(window.sessionStorage.getItem("choosecity"));
      const { data: res } = await request({
        method: "GET",
        url: "/EweiShopQaQuestion/findMorePolicy",
        params: {
          title: this.list.title,
          city: chooseCity.code,
        },
      });
      this.list3 = res.data;
      this.isFirst = false;
    },
    goNounExplain(id) {
      if (parseInt(this.id) !== id) {
        this.$router.replace({ path: "/cidian/nounexplain", query: { id } });
        this.getData();
      }
    },
    async getFindDictionarySearch() {
      const { data: res } = await request({
        method: "GET",
        url: "/EweiShopQaQuestion/findDictionarySearch",
      });
      if (res.code === 200) {
        const arr = [{ id: "", keyword: "热门搜索" }];
        const hotList = [...arr, ...res.data];
        this.SET_HOT_LIST(hotList);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nounExplain {
  background-color: #fff;
  padding: 43px 0px;
  .noun {
    margin-top: 35px;
    font-size: 16px;
  }
  .explain {
    margin-top: 12px;
    padding: 20px;
    border-top: 2px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: flex;
    & > div:nth-child(1) {
      width: 843px;
      padding-right: 44px;
      box-sizing: border-box;
      h1 {
        font-size: 24px;
        font-weight: bold;
      }
      div {
        margin-top: 23px;
        text-indent: 2em;
        font-size: 15px;
        line-height: 30px;
        /deep/p {
          text-indent: 2em !important;
          span {
            font-family: "Microsoft YaHei" !important;
          }
        }
      }
    }
    ul {
      width: 180px;
      min-height: 241px;
      border-left: 1px solid #cccccc;
      padding: 5px 0 5px 23px;
      li {
        margin-bottom: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        font-size: 14px;
        color: #585858;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      div {
        margin-bottom: 33px;
      }
    }
  }
  .relevant {
    padding: 28px 20px;
    & > div:nth-child(1) {
      font-size: 15px;
    }
    .policy {
      cursor: pointer;
      margin-top: 23px;
      div:nth-child(1) {
        font-size: 15px;
        color: #156ED0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      div:nth-child(2) {
        font-size: 14px;
        margin-top: 15px;
        color: #b8b8b8;
        span:nth-child(1) {
          margin-right: 25px;
        }
      }
    }
  }
}
</style>
