<template>
  <div class="search">
    <div>
      <h3>政策词典</h3>
      <div>专业名词术语，一点即通</div>
    </div>
    <div>
      <el-input placeholder="请输入关键词搜索" suffix-icon="el-icon-search" v-model="input" @change="handleSearch"></el-input>
     <ul class="searchHot">
       <li v-for="(item,index) in hotList" :key="index" @click="handleHot(item)">{{item.keyword}}</li>
     </ul>
    </div>
  </div>
</template>

<script>
// import { request } from '@/network'
import { mapState } from 'vuex';
export default {
  data () {
    return {
      input: ''
    };
  },
  computed: {
    ...mapState('cidian', [ 'hotList' ])
  },
  methods: {
    handleSearch () {
      this.$router.push({ name: '/cidian/index', params: { searchData: this.input } });
      this.$emit('search-data', this.input);
    },
    handleHot (value) {
      this.$emit('getHot', value);
    }
  }
};
</script>

<style lang="less" scoped>
.search {
  display: flex;
  margin-top: 30px;
  width: 1200px;
  height: 160px;
  background: url('~@/assets/pc/images/cidian/searchBG.png') no-repeat;
  background-size: 100% 100%;
  padding: 45px 60px 35px 45px;
  box-sizing: border-box;
  div:nth-child(1) {
    flex: 1;
    h3 {
      width: 130px;
      font-size: 30px;
      font-weight: 400;
    }
    div {
      margin-top: 17px;
      font-size: 14px;
      color: #000;
    }
  }
  &>div:nth-child(2) {
    width: 668px;
    /deep/.el-input__inner {
      height: 52px;
      box-shadow: 0 0 5px #156ED0;
    }
    /deep/.el-icon-search:before {
      height: inherit;
      display: block;
      content: '';
      background: url("~@/assets/pc/images/cidian/search.png") center no-repeat;
      background-size: 18px 18px;
    }
    .searchHot {
      margin-top: 15px;
      font-size: 14px;
      color: #156ED0;
      display: flex;
      li {
        margin-left: 20px;
      }
      li:nth-child(1) {
        color: #00122F;
        margin-left: 14px;
      }
    }
  }
}
</style>
